import React from "react";
import styled from "styled-components";
import BannerRedesignVast from "../components/BannerRedesignVast";
import Testimonials from "../components/ReUsables/Testimonials";

import { RespContainer } from "../styles/common";

import SectionEzDivisorNewsLetter from "../components/ReUsables/SectionEzDivisorNewsLetter";
import SectionEzDivisorConsultation from "../components/ReUsables/SectionEzDivisorConsultation";
import SectionWithImage from "../components/ReUsables/SectionWithImage";
import {
  GlobalOptions,
  TestimonialsType,
  LastThreePostsType,
  SectionWithImageType,
  ServicesWeOfferType,
} from "../shared/interfaces/graphql.interface";
import SectionLatestBlogPosts from "../components/ReUsables/SectionLatestBlogPosts";
import ServicesWeOffer from "../components/ReUsables/ServicesWeOffer";
import { Colors, Devices } from "../styles/variables";
import WhyUsSection from "../components/ReUsables/WhyUsSection";

interface PageDataProps {
  pageContext: { data: any };
}

interface PageContext {
  globalOptions: GlobalOptions;
  testimonials: TestimonialsType;
  title: string;
  content: string;
  acf: {
    banner_title: string;
    banner_title_2: string;
    banner_subtitle: string;
    banner_image: {
      localFile: any;
      alt_text: string;
    };
    banner_description: string;
    services_we_offer: [
      {
        icon: { source_url: string };
        title: string;
        description: string;
        link?: { url: string };
      }
    ];
    why_us: SectionWithImageType;
    competitive_pricing: SectionWithImageType;
    american_disabilities_act: SectionWithImageType;
  };
  lastThreePost: LastThreePostsType;
  services_we_offer: ServicesWeOfferType;
}

const home: React.FC<PageDataProps> = ({ pageContext, ...props }) => {
  let pageContextData: PageContext = pageContext.data;
  const { acf, testimonials } = pageContextData;

  return (
    <>
      <BannerRedesignVast
        title={acf.banner_title}
        title_2={pageContextData.acf.banner_title_2}
        subtitle={acf.banner_subtitle}
        description={acf.banner_description}
        banner={acf.banner_image}
      />

      <GrayBgWrapper>
        <ServicesWeOffer
          services_we_offer={pageContextData.services_we_offer}
          narrowBottomMargin
        />
      </GrayBgWrapper>

      <WhyUsSection
        title={acf.why_us.title}
        subtitle={acf.why_us.subtitle}
        description={acf.why_us.description}
        image={acf.why_us.image}
      />

      <GrayBgWrapper>
        <Testimonials testimonials={testimonials} />
      </GrayBgWrapper>

      {/* <SectionEzDivisorNewsLetter
        section={pageContextData.globalOptions.ez_divisor_newsletter}
      /> */}

      <SectionLatestBlogPosts lastThreePost={pageContextData.lastThreePost} />
    </>
  );
};

export default home;

const RespContainerHome = styled(RespContainer)`
  flex-direction: column;
`;

const GrayBgWrapper = styled("div")`
  background-color: ${Colors.gray7};
`;

const PricingAndAda = styled(RespContainer)`
  margin-top: 3.25rem;
  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-top: 13rem;
  }
`;
