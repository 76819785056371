import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Colors, Devices } from "../styles/variables";
import { EzDivisor, RespContainer } from "../styles/common";
import { useWindowSize } from "./CustomHooks/useWindowSize";
import { FontelloIcon } from "../styles/CommonComponents";
import { Link } from "gatsby";
import { AppButtonLink } from "../styles/common";
import {
  GatsbyImage,
  getImage,
  getSrcSet,
  IGatsbyImageData,
} from "gatsby-plugin-image";

interface BannerRedesignVastProps {
  title: string;
  title_2: string;
  subtitle: string;
  description: string;
  banner: {
    localFile: any;
    alt_text: string;
  };
  hideButton?: boolean;
}

const BannerRedesignVast: React.FC<BannerRedesignVastProps> = ({
  title,
  title_2,
  subtitle,
  description,
  banner,
  hideButton,
}) => {
  const BannerImageObject = getImage(banner?.localFile);
  return (
    <BannerWrapper>
      <TextsContent>
        <Title>
          {title}
          <TitlePattern>{title_2}</TitlePattern>
        </Title>
        <EzDivisorBanner alwaysBlue />
        <BoldText>{subtitle}</BoldText>
        <Text>{description}</Text>
      </TextsContent>
      <BannerImageContent>
        <BannerImage
          image={getImage(banner?.localFile) as IGatsbyImageData}
          // srcset={getSrcSet(banner?.localFile)}
          alt={banner?.alt_text}
        />
        <ButtonWrapper hideButton={hideButton}>
          <ConsultationButton to={`/contact`}>
            GET A FREE CONSULTATION
          </ConsultationButton>
        </ButtonWrapper>
      </BannerImageContent>
    </BannerWrapper>
  );
};

export default BannerRedesignVast;

const BannerWrapper = styled(RespContainer)`
  display: flex;

  flex-direction: column;
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 3rem;
    margin-top: 40px;

    /* align-items: center;
    margin-top: 0; */
  }
`;

const TextsContent = styled("div")`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    display: flex;
    flex-direction: column;
    width: 60% !important;

    margin-bottom: 0;
  }
`;

const BannerImageContent = styled("div")`
  align-self: center;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    width: 40%;
  }
`;

const EzDivisorBanner = styled(EzDivisor)`
  margin-top: 0.875rem;
`;

const TitlePattern = styled("span")`
  display: block;
  color: ${Colors.sharpBlue};
`;

const Title = styled("h1")`
  color: ${Colors.ezBlack};
  font-size: 2.5rem;
  font-weight: 500;
  margin: 10px 0 0;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-bottom: 3rem;
    font-size: 3.5rem;
    margin: 0;
    max-width: 36rem;
  }

  /* >= 1440px; */
  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    /* margin-bottom: 5.25rem; */
    margin-bottom: 3rem;

    font-size: 4.5rem;
    max-width: 54rem;

    margin-top: 38px;
    line-height: 1.1;
    margin-bottom: 6px;
  }

  /* >= 1800px; */
  @media all and (min-width: ${Devices.laptopBreakpoint}) {
    font-size: 4rem;
  }
`;

const BoldText = styled("h2")`
  font-weight: 600;
  font-size: 20px;
  color: ${Colors.ezBlack};
  margin-top: 1.5rem;
  line-height: 140%;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    font-size: 24px;
    max-width: 95%;
  }

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-top: 1.5rem;
  }
`;

const Text = styled("p")`
  font-weight: 300;
  font-size: 1rem;
  color: ${Colors.solidGray};
  line-height: 1.4;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    line-height: 148%;
    max-width: 95%;
  }

  /* >= 1800px; */
  @media all and (min-width: ${Devices.laptopBreakpoint}) {
    font-size: 1.125rem;
  }
`;

const BannerImage = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50px 50px 0 0;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    border-radius: 100px 0 0 100px;
  }

  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    max-width: 652px;
    /* height: 550px; */
    /* display: none; */
  }
`;

const ConsultationButton = styled(AppButtonLink)`
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    display: none;
  }
`;

const ButtonWrapper = styled("div")<{ hideButton?: boolean }>`
  margin-top: 45px;
  display: ${(props) => (props.hideButton ? "none" : "flex")};
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
`;
