import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Colors, Devices } from "../../styles/variables";
import { EzDivisor, RespContainer } from "../../styles/common";
import { AppButtonLink } from "../../styles/common";
import { Link } from "gatsby";
import { FontelloIcon } from "../../styles/CommonComponents";
import EzReadMore from "./EzReadMore";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";

interface WhyUsSectionProps {
  title: string;
  subtitle: string;
  description: string;
  image: {
    localFile?: any;
    alt_text: string;
  };
  hideButton?: boolean;
}

const WhyUsSection: React.FC<WhyUsSectionProps> = ({
  title,
  subtitle,
  description,
  image,
}) => {
  return (
    <WhyUsWrapper>
      <ImageContent>
        <Image
          image={getImage(image?.localFile) as IGatsbyImageData}
          alt={image?.alt_text}
        />
      </ImageContent>

      <TextsContent>
        <Title>{title}</Title>
        <EzDivisorBanner alwaysBlue />

        <ImageContentMobile>
          <ImageMobile
            image={getImage(image?.localFile) as IGatsbyImageData}
            alt={image?.alt_text}
          />
        </ImageContentMobile>

        <Subtitle>{subtitle}</Subtitle>
        <Description dangerouslySetInnerHTML={{ __html: description }} />

        <ReadMoreWrapper>
          <EzReadMore to={"/about"}></EzReadMore>
        </ReadMoreWrapper>
      </TextsContent>
    </WhyUsWrapper>
  );
};

export default WhyUsSection;

const WhyUsWrapper = styled(RespContainer)`
  display: flex;

  flex-direction: column;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    flex-direction: row;
    align-items: flex-start;
    margin-top: 71px;
    margin-bottom: 154px;

    align-items: center;
  }
`;

const ImageContent = styled("div")`
  align-self: center;
  display: none;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    display: block;
    width: 50%;
  }
`;

const Image = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: none;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    display: block;
  }

  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    /* max-width: 652px; */
    /* height: 550px; */
    /* display: none; */
  }
`;

const ImageContentMobile = styled("div")`
  align-self: center;
  margin-top: 46px;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    display: none;
  }
`;

const ImageMobile = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50px 50px 0 0;
  max-height: 550px;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    display: none;
  }
`;

const TextsContent = styled("div")`
  display: flex;
  flex-direction: column;
  margin-bottom: 52px;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    display: flex;
    flex-direction: column;
    width: 50% !important;
    padding-left: 55px;

    margin-bottom: 0;
    margin-top: 90px;
  }

  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    padding-left: 110px;
  }
`;

const Title = styled("h1")`
  color: ${Colors.ezBlack};
  font-size: 2rem;
  font-weight: 500;
  margin: 38px 0 0;

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    margin-bottom: 20px;
    line-height: 148%;
    font-weight: 600;
  }

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-bottom: 40px;
    font-size: 3.5rem;
    line-height: 148%;
    margin: 0;
    font-weight: 600;
  }

  /* >= 1440px; */
  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    /* margin-bottom: 5.25rem; */
    font-size: 60px;

    margin-top: 0;
    line-height: 100%;
    margin-bottom: 44px;
  }

  /* >= 1800px; */
  /* @media all and (min-width: ${Devices.laptopBreakpoint}) {
    margin-bottom: 6.375rem;
    font-size: 5.4rem;
    padding: 0 15px;
  } */
`;

const EzDivisorBanner = styled(EzDivisor)`
  margin-top: 1.5rem;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    margin-top: 0;
    margin-bottom: 2.125rem;
  }
`;

const Subtitle = styled("h2")`
  font-weight: 500;
  font-size: 20px;
  color: ${Colors.ezBlack};
  margin-top: 56px;
  line-height: 140%;

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    margin-top: 0;
    margin-bottom: 15px;

    font-size: 24px;
    line-height: 148%;
    max-width: 95%;
  }

  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    font-size: 28px;
    font-weight: 600;
  }
`;

const Description = styled("h3")`
  font-weight: 500;
  font-size: 1rem;
  color: ${Colors.solidGray};
  margin-top: 10px;
  line-height: 172%;
  margin-bottom: 12px;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    margin-top: 0;
    margin-bottom: 12px;

    font-size: 15px;
    max-width: 95%;
    line-height: 198%;

    > p {
      margin-bottom: 25px;
    }
  }
`;

const ReadMoreWrapper = styled("div")`
  display: flex;
  justify-content: center;
  font-size: 1.125rem;
  font-weight: 600;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    justify-content: flex-start;
  }
`;

const ReadMoreText = styled("span")``;
